import { NgModule } from '@angular/core';
import { HighlightPipe } from './highlight.pipe';


@NgModule({
  imports: [],
  exports: [HighlightPipe],
  declarations: [HighlightPipe],
  providers: [],
})
export class SharedPipesModule { }
